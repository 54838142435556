// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./blueprint-icons-16.ttf?141c37d6ab6c8c3e1bd4e5eaab0cac02");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./blueprint-icons-16.eot?141c37d6ab6c8c3e1bd4e5eaab0cac02");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./blueprint-icons-16.woff2?141c37d6ab6c8c3e1bd4e5eaab0cac02");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./blueprint-icons-16.woff?141c37d6ab6c8c3e1bd4e5eaab0cac02");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./blueprint-icons-16.svg?141c37d6ab6c8c3e1bd4e5eaab0cac02");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./blueprint-icons-20.ttf?b3487eb25dedb5a8cee094896fb5d2b7");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./blueprint-icons-20.eot?b3487eb25dedb5a8cee094896fb5d2b7");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./blueprint-icons-20.woff2?b3487eb25dedb5a8cee094896fb5d2b7");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./blueprint-icons-20.woff?b3487eb25dedb5a8cee094896fb5d2b7");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./blueprint-icons-20.svg?b3487eb25dedb5a8cee094896fb5d2b7");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#blueprint-icons-16" });
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___, { hash: "#blueprint-icons-20" });
// Module
exports.push([module.id, "@font-face{\n  font-family:\"blueprint-icons-16\";\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");\n}\n@font-face{\n  font-family:\"blueprint-icons-20\";\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"embedded-opentype\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"svg\");\n}", ""]);
// Exports
module.exports = exports;
